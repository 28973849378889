import "./ListItem.scss";

import { forwardRef } from "react";

import { ITransactionData } from "../../../Models/Advancement";

interface RequestAdvancementStepOneProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  transaction: ITransactionData;
}

const ListItem = forwardRef<HTMLInputElement, RequestAdvancementStepOneProps>(
  ({ transaction }, ref) => {
    const formatNumber = (value: number): string => {
      return new Intl.NumberFormat("en-US").format(value);
    };

    const calculateTotal = () => {
      const total = transaction.details.rent.value * transaction.details.months;
      const baseCommissionRate = 0.165;
      const extraCommissionRate =
        transaction.details.months > 6
          ? (transaction.details.months - 6) * 0.012
          : 0;

      const commissionRate = baseCommissionRate + extraCommissionRate;
      const commission = parseFloat((total * commissionRate).toFixed(2));

      return {
        total: formatNumber(total - commission),
        commission: commissionRate,
      };
    };

    return (
      <div className="advancement-item">
        <p className="advancement-item--heading">
          Adelanto de renta de {calculateTotal().total} por{" "}
          {transaction.details.months} meses
        </p>
      </div>
    );
  }
);

export default ListItem;
