import "./CustomDialog.scss";
import React from "react";

interface DialogContainerProps {
  children: React.ReactNode;
  allowClose: boolean;
  isOpen: boolean;
  closeDialog: () => void;
}

const DialogContainer: React.FC<DialogContainerProps> = ({
  children,
  allowClose,
  isOpen,
  closeDialog,
}) => {
  if (!isOpen) return null;

  return (
    <div className="overlay" onClick={allowClose ? closeDialog : undefined}>
      <div className="dialog" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default DialogContainer;
