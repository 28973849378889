import axios, { AxiosRequestConfig } from "axios";

class ChiefService {
  public async post<T>(url: string, body: object, headers: AxiosRequestConfig): Promise<T> {
    try {
      const { data, status } = await axios.post<T>(url, body, headers);

      if (status > 401 && status < 404) {
        window.location.href = "/login";
      }

      return data;
    } catch (error) {
      console.error("Error sending POST request:", error);
      throw error;
    }
  }

  public async get<T>(url: string, headers?: AxiosRequestConfig): Promise<T> {
    try {
      const { data, status } = await axios.get<T>(url, headers);

      if (status > 401 && status < 404) {
        window.location.href = "/login";
      }

      return data;
    } catch (error) {
      console.error("Error sending GET request:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ChiefService();
