import "./CreateContract.scss";
import { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import AppBar from "../../ui/AppBar/AppBar";
import Button from "../../ui/Button/Button";

import OneIdentification from "../../Components/ContractSteps/OneIdentification/OneIdentification";
import ThreePayment from "../../Components/ContractSteps/ThreePayment/ThreePayment";
import TwoProperty from "../../Components/ContractSteps/TwoProperty/TwoProperty";

import { generatePDF } from "../../Helpers/PDFHelper";
import { contract } from "../../Templates/RentContract";
import {
  TContract,
  TThreePayment,
  TOneIdentification,
  TTwoProperty,
} from "../../Models/Contract";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../Services/FirebaseConfig";

function CreateContractPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [contractData, setContractData] = useState<TContract>({} as TContract);
  const [isStepValid, setIsStepValid] = useState(false);

  const handleChange = (data: {
    [key: string]: TOneIdentification | TTwoProperty | TThreePayment;
  }) => {
    setContractData(() => ({
      ...contractData,
      ...data,
    }));
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        const identification = contractData.identification;

        if (
          identification &&
          identification.lessor.name &&
          identification.lessor.document.number &&
          identification.lessor.email &&
          identification.tenant.name &&
          identification.tenant.document.number
        ) {
          setIsStepValid(true);
        } else {
          setIsStepValid(false);
        }
        break;
      case 1:
        const property = contractData.property;
        if (
          property &&
          property.address &&
          property.startDate &&
          property.endDate
        ) {
          setIsStepValid(true);
        } else {
          setIsStepValid(false);
        }
        break;
      case 2:
        const payment = contractData.payment;
        if (
          payment &&
          payment.accountNumber &&
          payment.payday &&
          payment.depositAmount &&
          payment.allowTermsAndConditions.toLowerCase() === "true"
        ) {
          setIsStepValid(true);
        } else {
          setIsStepValid(false);
        }
        break;
      default:
        setIsStepValid(false);
    }
  };

  useEffect(() => {
    validateStep();
  }, [contractData, currentStep]);

  const handleNextStep = () => {
    if (isStepValid && currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const steps = [
    {
      title: "Identificaciones",
      component: <OneIdentification onDataChange={handleChange} />,
      action: {
        text: "Siguiente",
        icon: <ArrowForwardIosIcon />,
        action: handleNextStep,
      },
    },
    {
      title: "Inmueble",
      component: <TwoProperty onDataChange={handleChange} />,
      action: {
        text: "Siguiente",
        icon: <ArrowForwardIosIcon />,
        action: handleNextStep,
      },
    },
    {
      title: "Monto",
      component: <ThreePayment onDataChange={handleChange} />,
      action: {
        text: "Descargar contrato",
        icon: <FileDownloadIcon />,
        action: () => isStepValid && generateAndSend(),
      },
    },
  ];

  const generateAndSend = async () => {
    try {
      await addDoc(collection(db, "contracts"), {
        ...contractData,
        property: {
          ...contractData.property,
          startDate: contractData.property.startDate?.format("DD/MM/YYYY"),
          endDate: contractData.property.endDate?.format("DD/MM/YYYY"),
        },
      });

      generatePDF(contract(contractData));
    } catch (e) {
      console.log(e);
    }
  };

  const Stepper = () => {
    return (
      <div className="stepper">
        {steps.map((step, index) => {
          let status =
            index < currentStep
              ? "completed"
              : index === currentStep
              ? "in-progress"
              : "to-be-done";

          return (
            <div
              key={index}
              className={`stepper__step stepper__step--${status}`}
            >
              {status === "completed" ? <CheckIcon /> : `${index + 1}`}
              {status === "completed" ? (
                ""
              ) : (
                <span className="separator"> - </span>
              )}
              <span className="title"> {step.title}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="contract-page">
      <AppBar />
      <div className="contract-page__container">
        <Stepper />

        {steps[currentStep].component}

        <div className="contract-page__container--actions">
          <Button
            variant="filled"
            text={steps[currentStep].action.text}
            icon={steps[currentStep].action.icon}
            onClick={steps[currentStep].action.action}
            disabled={!isStepValid}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateContractPage;
