import "./VerifyCodeDialog.scss";
import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { IActor } from "../RequestAdvance/StepOne/StepOne";
import { setToken } from "../../Store/User";
import authService, { TIntent } from "../../Services/AuthService";

import DialogContainer from "../../ui/DialogContainer/DialogContainer";
import TextBox from "../../ui/TextBox/TextBox";
import Button from "../../ui/Button/Button";

interface VerifyCodeDialogProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  user: IActor;
  intent: TIntent;
  isOpen: boolean;
  requestCode: () => void;
  closeDialog: () => void;
}

const VerifyCodeDialog = forwardRef<HTMLInputElement, VerifyCodeDialogProps>(
  ({ user, intent, isOpen, closeDialog, requestCode }, ref) => {
    const [code, setCode] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { email, name } = user;
    const dispatch = useDispatch();

    const login = () => {
      setLoading(true);

      if (code) {
        authService
          .login(name, intent, email, code)
          .then((data) => {
            if (data.accessToken) {
              dispatch(setToken(data.accessToken));
            }

            closeDialog();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { value } = e.target;
      setCode(value);
    };

    return (
      <DialogContainer
        allowClose={false}
        isOpen={isOpen}
        closeDialog={closeDialog}
      >
        <div className="verify-code-dialog">
          <h2>Verificar correo</h2>

          <div className="verify-code-dialog__body">
            <p>
              Hemos enviado un código de verificación a el correo{" "}
              <strong>{email}</strong> para verificar la solicitud
            </p>

            <TextBox
              name="code"
              placeholder="Código de verificación"
              type="number"
              maxLength={6}
              onChange={handleChange}
            />
          </div>

          <div className="verify-code-dialog__action">
            <Button
              variant="filled"
              text="Verificar"
              icon={<ArrowForwardIosIcon />}
              disabled={!(code && code.length >= 6) || loading}
              onClick={login}
            />
          </div>

          <div className="divider" />

          <div className="verify-code-dialog__resend">
            <Button
              variant="text"
              text="Enviar un nuevo código"
              onClick={requestCode}
            />
          </div>
        </div>
      </DialogContainer>
    );
  }
);

export default VerifyCodeDialog;
