import "./Advancement.scss";

import { useEffect, useState } from "react";

import AdvancementListItem from "../../Components/Advancement/ListItem/ListItem";
import advancementService from "../../Services/AdvancementService";

import AppBar from "../../ui/AppBar/AppBar";
import { useAppSelector } from "../../Store/Store";
import { ITransactions } from "../../Models/Advancement";

function AdvancementPage() {
  const [advancement, setAdvancement] = useState({} as ITransactions);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const accessToken = useAppSelector((state) => state.user.token) ?? "";

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      return "Buenos días";
    } else if (hour >= 12 && hour < 18) {
      return "Buenas tardes";
    } else {
      return "Buenas noches";
    }
  };

  const fetchAdvancements = () => {
    advancementService
      .getRequests(accessToken)
      .then((data: ITransactions) => setAdvancement(data))
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAdvancements();
  }, []);

  return (
    <>
      <AppBar allowedSignOut={true} />

      <div className="advancement">
        <div className="advancement__container">
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <h1 className="advancement__container--heading">
                {getGreeting()}, {advancement.user.name}
              </h1>

              <div className="advancement__container__advancement-list">
                <p className="advancement__container--subheading">Solicitudes</p>

                <div className="advancement__container--list">
                  {advancement.transactions.map((item, index) => (
                    <AdvancementListItem key={index} transaction={item} />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AdvancementPage;
