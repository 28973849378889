import "./StepTwo.scss";

import dayjs from "dayjs";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import DatePicker, {
  DatePickerNewValue,
} from "../../../ui/DatePicker/DatePicker";
import Button from "../../../ui/Button/Button";
import TextBox from "../../../ui/TextBox/TextBox";
import { setContractDetails } from "../../../Store/RequestAdvance";
import { RootState } from "../../../Store/Store";

export interface IContractDetails {
  payDay: string;
  location: string;
  startDate: number;
  endDate: number;
}

interface RequestAdvancementStepTwoProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  nextStep: () => void;
  previousStep: () => void;
}

const RequestAdvancementStepTwo = forwardRef<
  HTMLInputElement,
  RequestAdvancementStepTwoProps
>(({ nextStep, previousStep }, ref) => {
  const dispatch = useDispatch();
  const storeContractDetails = useSelector(
    (state: RootState) => state.requestAdvance.contractDetails
  );

  const [contractDetails, setLocalContractDetails] = useState<IContractDetails>(
    JSON.parse(JSON.stringify(storeContractDetails))
  );
  const [loading, setLoading] = useState(false);
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);
  const [isAnyFieldInvalid, setIsAnyFieldInvalid] = useState(false);

  const handleNextStep = () => {
    setLoading(true);
    dispatch(setContractDetails(contractDetails));

    nextStep();
    setLoading(false);
  };

  const handlePreviousStep = () => {
    previousStep();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    callback?: () => void
  ) => {
    const { name, value } = e.target;
    const contractDetailsUpdate: IContractDetails = { ...contractDetails };
    const keys = name.split(".");
    let currentLevel: any = contractDetailsUpdate;

    keys.slice(0, -1).forEach((key) => {
      currentLevel = currentLevel[key as keyof typeof currentLevel];
    });
    currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = value;

    setLocalContractDetails(contractDetailsUpdate);

    if (callback) {
      callback();
    }
  };

  const handleDateDateChange = (data: DatePickerNewValue) => {
    const { name, newDate } = data;
    const contractDetailsUpdate: IContractDetails = { ...contractDetails };
    const keys = name.split(".");
    let currentLevel: any = contractDetailsUpdate;

    keys.slice(0, -1).forEach((key) => {
      currentLevel = currentLevel[key as keyof typeof currentLevel];
    });

    currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = newDate
      .toDate()
      .getTime();

    setLocalContractDetails(contractDetailsUpdate);
  };

  useEffect(() => {
    const allDataFilled =
      !!contractDetails.payDay &&
      !!contractDetails.location &&
      !!contractDetails.startDate &&
      !!contractDetails.endDate &&
      !isAnyFieldInvalid;

    setIsAllDataFilled(allDataFilled);
  }, [contractDetails, isAnyFieldInvalid]);

  return (
    <div className="request-advancement-step-two">
      <h2 className="request-advancement-step-two--heading">
        Detalles del contrato
      </h2>

      <div className="request-advancement-step-two__form--group">
        <TextBox
          name="payDay"
          placeholder="Día de pago"
          type="number"
          maxLength={2}
          value={contractDetails.payDay}
          onChange={handleChange}
          fieldValidation={setIsAnyFieldInvalid}
          customValidation={(value) => {
            if (value > 31) {
              return "El día de pago no puede ser mayor a el día 31";
            }

            if (value < 1) {
              return "El día de pago no puede ser menor a 1";
            }
          }}
        />

        <TextBox
          name="location"
          placeholder="Dirección del inmueble"
          value={contractDetails.location}
          onChange={handleChange}
        />
      </div>

      <div className="divider" />

      <h2 className="request-advancement-step-two--heading">
        Duración del contrato
      </h2>

      <div className="request-advancement-step-two__form--group">
        <DatePicker
          name="startDate"
          value={
            contractDetails.startDate
              ? dayjs(contractDetails.startDate)
              : undefined
          }
          onChange={handleDateDateChange}
          placeholder="Fecha de inicio"
        />
        <DatePicker
          name="endDate"
          value={
            contractDetails.endDate ? dayjs(contractDetails.endDate) : undefined
          }
          minDate={
            contractDetails.startDate
              ? dayjs(contractDetails.startDate)
              : undefined
          }
          onChange={handleDateDateChange}
          placeholder="Fecha de finalización"
        />
      </div>

      <div className="divider" />
      <div className="request-advancement-step-two__actions">
        <Button
          variant="outlined"
          text="Atrás"
          icon={<ArrowBackIosIcon />}
          onClick={handlePreviousStep}
        />
        <Button
          variant="filled"
          text="Siguiente"
          icon={<ArrowForwardIosIcon />}
          disabled={!isAllDataFilled || loading}
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
});

export default RequestAdvancementStepTwo;
