import "./StepOne.scss";

import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Button from "../../../ui/Button/Button";
import TextBox from "../../../ui/TextBox/TextBox";

import VerifyCodeDialog from "../../VerifyCodeDialog/VerifyCodeDialog";
import authService from "../../../Services/AuthService";
import { OUNER_SESSION } from "../../../consts";
import { RootState, useAppSelector } from "../../../Store/Store";
import { setActors } from "../../../Store/RequestAdvance";

export interface IActor {
  name: string;
  lastName: string;
  nationalId: string;
  email: string;
  phoneNumber: string;
}

export interface IContractActors {
  owner: IActor;
  tenant: IActor;
}

interface RequestAdvancementStepOneProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  nextStep: () => void;
  previousStep: () => void;
}

const RequestAdvancementStepOne = forwardRef<
  HTMLInputElement,
  RequestAdvancementStepOneProps
>(({ nextStep }, ref) => {
  const dispatch = useDispatch();
  const accessToken = useAppSelector((state) => state.user.token);
  const storeContractActors = useSelector(
    (state: RootState) => state.requestAdvance.actors
  );

  const [isOwnerInformationComplete, setIsOwnerInformationComplete] =
    useState(false);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [isAnyFieldInvalid, setIsAnyFieldInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ounerSession, setOunerSession] = useState<string | null>(accessToken);

  const [contractActors, setContractActors] = useState<IContractActors>(
    JSON.parse(JSON.stringify(storeContractActors))
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    callback?: () => void
  ) => {
    const { name, value } = e.target;
    const contractActorsUpdate: IContractActors = { ...contractActors };
    const keys = name.split(".");
    let currentLevel: any = contractActorsUpdate;

    keys.slice(0, -1).forEach((key) => {
      currentLevel = currentLevel[key as keyof typeof currentLevel];
    });
    currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = value;

    setContractActors(contractActorsUpdate);

    if (callback) {
      callback();
    }
  };

  const requestCode = () => {
    setLoading(true);

    authService
      .sendEmailCode(
        "sign-up",
        contractActors.owner.email,
        contractActors.owner.name
      )
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setIsModalOpen(true);
      });
  };

  const handleNextStep = () => {
    setLoading(true);
    dispatch(setActors(contractActors));

    nextStep();
    setLoading(false);
  };

  useEffect(() => {
    const owner = contractActors.owner;
    const allDataFilled =
      !!owner.name &&
      !!owner.lastName &&
      !!owner.email &&
      !!owner.nationalId &&
      !!owner.phoneNumber &&
      !isAnyFieldInvalid;

    setIsOwnerInformationComplete(allDataFilled);
  }, [contractActors, isAnyFieldInvalid]);

  useEffect(() => {
    if (accessToken) {
      const owner = contractActors.owner;
      const allOwnerDataFilled =
        !!owner.name &&
        !!owner.lastName &&
        !!owner.email &&
        !!owner.nationalId &&
        !!owner.phoneNumber &&
        !isAnyFieldInvalid;

      const tenant = contractActors.tenant;
      const allTenantDataFilled =
        !!tenant.name &&
        !!tenant.lastName &&
        !!tenant.email &&
        !!tenant.nationalId &&
        !!tenant.phoneNumber &&
        !isAnyFieldInvalid;

      setIsAllFieldsFilled(allOwnerDataFilled && allTenantDataFilled);
    }
  }, [accessToken, contractActors, isAnyFieldInvalid]);

  return (
    <div className="request-advancement-step-one">
      <h2 className="request-advancement-step-one--heading">
        Información del arrendador
      </h2>

      <div className="request-advancement-step-one__form">
        <div className="request-advancement-step-one__form--group">
          <TextBox
            name="owner.name"
            placeholder="Nombre"
            value={contractActors.owner.name}
            onChange={handleChange}
          />
          <TextBox
            name="owner.lastName"
            placeholder="Apellidos"
            value={contractActors.owner.lastName}
            onChange={handleChange}
          />
        </div>
        <TextBox
          name="owner.nationalId"
          placeholder="Número de documento"
          value={contractActors.owner.nationalId}
          onChange={handleChange}
        />
        <div className="request-advancement-step-one__form--group">
          <TextBox
            name="owner.email"
            placeholder="Correo"
            type="email"
            value={contractActors.owner.email}
            errorText="Correo invalido"
            fieldValidation={setIsAnyFieldInvalid}
            onChange={handleChange}
          />
          <TextBox
            name="owner.phoneNumber"
            placeholder="Número de celular"
            type="number"
            value={contractActors.owner.phoneNumber}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="divider" />

      {ounerSession && (
        <>
          <h2 className="request-advancement-step-one--heading">
            Información del arrendatario
          </h2>

          <div className="request-advancement-step-one__form">
            <div className="request-advancement-step-one__form--group">
              <TextBox
                name="tenant.name"
                placeholder="Nombre"
                value={contractActors.tenant.name}
                onChange={handleChange}
              />
              <TextBox
                name="tenant.lastName"
                placeholder="Apellidos"
                value={contractActors.tenant.lastName}
                onChange={handleChange}
              />
            </div>
            <TextBox
              name="tenant.nationalId"
              placeholder="Número de documento"
              value={contractActors.tenant.nationalId}
              onChange={handleChange}
            />
            <div className="request-advancement-step-one__form--group">
              <TextBox
                name="tenant.email"
                placeholder="Correo"
                type="email"
                value={contractActors.tenant.email}
                errorText="Correo invalido"
                fieldValidation={setIsAnyFieldInvalid}
                onChange={handleChange}
              />
              <TextBox
                name="tenant.phoneNumber"
                placeholder="Número de celular"
                type="number"
                value={contractActors.tenant.phoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
        </>
      )}

      <div className="request-advancement-step-one__actions">
        {ounerSession ? (
          <Button
            variant="filled"
            text="Siguiente"
            icon={<ArrowForwardIosIcon />}
            disabled={!isAllFieldsFilled || loading}
            onClick={handleNextStep}
          />
        ) : (
          <Button
            variant="filled"
            text="Verificar correo electrónico"
            icon={<ArrowForwardIosIcon />}
            disabled={!isOwnerInformationComplete || loading}
            onClick={requestCode}
          />
        )}
      </div>

      <VerifyCodeDialog
        user={contractActors.owner}
        intent="sign-up"
        isOpen={isModalOpen}
        requestCode={requestCode}
        closeDialog={() => {
          setIsModalOpen(false);

          if (localStorage.getItem(OUNER_SESSION)) {
            setOunerSession(localStorage.getItem(OUNER_SESSION) ?? null);
          }
        }}
      />
    </div>
  );
});

export default RequestAdvancementStepOne;
