import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./Store/Store";

import HomePage from "./Pages/Home/Home";
import AdvancementPage from "./Pages/Advancement/Advancement";
import AffiliatesPage from "./Pages/Affiliates/Affiliates";
import CreateContractPage from "./Pages/CreateContract/CreateContract";
import ContractPage from "./Pages/Contract/Contract";
import SimulateAdvancement from "./Pages/SimulateAdvancement/SimulateAdvancement";
import RequestAdvance from "./Pages/RequestAdvance/RequestAdvance";
import TermsAndConditions from "./Pages/Terms&Conditions/TermsAndConditions";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/afiliados" element={<AffiliatesPage />} />
        <Route path="/contratos" element={<ContractPage />} />
        <Route path="/contratos/crear" element={<CreateContractPage />} />
        <Route
          path="/contratos/terminos-y-condiciones"
          element={<TermsAndConditions />}
        />
        <Route path="/simular-adelanto" element={<SimulateAdvancement />} />
        <Route path="/solicitar-adelanto" element={<RequestAdvance />} />

        <Route element={<PrivateRoute />}>
          <Route path="/adelantos" element={<AdvancementPage />} />
        </Route>
      </Routes>
    </Provider>
  );
}

export default App;
