import "./StepThree.scss";

import { forwardRef } from "react";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Button from "../../../ui/Button/Button";
import { RootState } from "../../../Store/Store";
import { IActor } from "../StepOne/StepOne";

export interface IContractDetails {
  payDay: string;
  location: string;
  startDate: number;
  endDate: number;
}

interface RequestAdvancementStepThreeProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  nextStep: () => void;
  previousStep: () => void;
}

const RequestAdvancementStepThree = forwardRef<
  HTMLInputElement,
  RequestAdvancementStepThreeProps
>(({ nextStep, previousStep }, ref) => {
  const handlePreviousStep = () => {
    previousStep();
  };

  const { transaction, actors, contractDetails } = useSelector(
    (state: RootState) => state.requestAdvance
  );

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("es-ES", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  const TransactionInformation = () => {
    return (
      <>
        {transaction.total && (
          <ul>
            <li>
              Adelanto de <strong>{formatCurrency(transaction.total)}</strong>{" "}
              MXN
            </li>
            <li>
              Meses de adelanto: <strong>{transaction.advanceMonths}</strong>
            </li>
            <li>
              Comisión aplicada a el adelanto:{" "}
              <strong>{transaction.commission?.toFixed(2)}%</strong>
            </li>
          </ul>
        )}
      </>
    );
  };

  const ContractActorInformation = ({ data }: { data: IActor }) => {
    return (
      <ul>
        <li>
          {data.name} {data.lastName}
        </li>
        <li>{data.nationalId}</li>
        <li>{data.email}</li>
        <li>{data.phoneNumber}</li>
      </ul>
    );
  };

  const ContractInformation = () => (
    <ul>
      <li>
        Se paga el día: <strong>{contractDetails.payDay}</strong>
      </li>
      <li>
        Inmueble ubicado en la dirección:{" "}
        <strong>{contractDetails.location}</strong>
      </li>
      <li>
        El contrato se comenzó el{" "}
        <strong>{formatDate(contractDetails.startDate)}</strong>, y durará hasta{" "}
        <strong>{formatDate(contractDetails.endDate)}</strong>
      </li>
    </ul>
  );

  return (
    <div className="request-advancement-step-three">
      <div className="request-advancement-step-three__section">
        <TransactionInformation />
      </div>
      <div className="divider" />

      <div className="request-advancement-step-three__section">
        <h2 className="request-advancement-step-three__section--heading">
          Arrendador
        </h2>
        <ContractActorInformation data={actors.owner} />
      </div>

      <div className="request-advancement-step-three__section">
        <h2 className="request-advancement-step-three__section--heading">
          Arrendatario
        </h2>
        <ContractActorInformation data={actors.tenant} />
      </div>
      <div className="divider" />

      <div className="request-advancement-step-three__section">
        <h2 className="request-advancement-step-three__section--heading">
          Detalles del contrato
        </h2>
        <ContractInformation />
      </div>
      <div className="divider" />
      <div className="request-advancement-step-three__actions">
        <Button
          variant="outlined"
          text="Atrás"
          icon={<ArrowBackIosIcon />}
          onClick={handlePreviousStep}
        />
        <Button
          variant="filled"
          text="Solicitar"
          icon={<ArrowForwardIosIcon />}
          onClick={nextStep}
        />
      </div>
    </div>
  );
});

export default RequestAdvancementStepThree;
