import "./Contract.scss";

import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CoverImage from "../../Assets/HomePage/cover_image.png";
import AppBar from "../../ui/AppBar/AppBar";
import Button from "../../ui/Button/Button";

function ContractPage() {
  const navigate = useNavigate();

  return (
    <div className="home-page">
      <AppBar>
        <ul>
          <li>
            <a href="https://ouner.mx" target="_blank" rel="noreferrer">
              Inicio
            </a>
          </li>
          <li>
            <a href="https://ouner.mx" target="_blank" rel="noreferrer">
              ¿Quiénes somos?
            </a>
          </li>
          <li>
            <a href="https://ouner.mx" target="_blank" rel="noreferrer">
              ¿Qué hacemos?
            </a>
          </li>
          <li>
            <a href="https://ouner.mx" target="_blank" rel="noreferrer">
              ¿Cómo funciona?
            </a>
          </li>
        </ul>
      </AppBar>

      <div className="home-page__content">
        <h1>
          Crea tu <strong>contrato</strong> de <strong>arrendamiento</strong> en
          unos pocos <strong>clics, gratis</strong>
        </h1>

        <span className="home-page__content--actions">
          <Button
            text="Obtener mi contrato gratis"
            icon={<ArrowForwardIosIcon />}
            variant="filled"
            onClick={() => navigate("/contratos/crear")}
          />
          <Button
            text="Conocer más sobre Ouner"
            variant="text"
            onClick={() => navigate("/")}
          />
        </span>
        <img
          src={CoverImage}
          alt="Template de arrendamiento"
          className="home-page__content--image"
        />
      </div>
    </div>
  );
}

export default ContractPage;
