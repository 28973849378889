import { createSlice } from "@reduxjs/toolkit";

import { IActor } from "../Components/RequestAdvance/StepOne/StepOne";
import { IContractDetails } from "../Components/RequestAdvance/StepTwo/StepTwo";
import { ITransaction } from "../Models/Advancement";

const requestAdvanceSlice = createSlice({
  name: 'requestAdvance',
  initialState: {
    transaction: {} as ITransaction,
    actors: {
      owner: {} as IActor,
      tenant: {} as IActor
    },
    contractDetails: {} as IContractDetails
  },
  reducers: {
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setActors: (state, action) => {
      state.actors = action.payload;
    },
    setContractDetails: (state, action) => {
      state.contractDetails = action.payload
    }
  },
});

export const { setTransaction, setActors, setContractDetails } = requestAdvanceSlice.actions;

export default requestAdvanceSlice;
