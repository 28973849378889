import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./User";
import requestAdvanceSlice from "./RequestAdvance";
import { useSelector } from "react-redux";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    requestAdvance: requestAdvanceSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector = <T>(selector: (state: RootState) => T) => useSelector<RootState, T>(selector);

export default store;
