import chiefService from './ChiefService';

import { IRequestAdvance } from '../Models/Advancement';

class AdvancementService {
  private baseUrl: string = process.env.REACT_APP_CHIEF_SERVICE || "";

  public async request(body: IRequestAdvance, token: string): Promise<any> {
    try {
      return await chiefService.post(
        `${this.baseUrl}/advancement/request`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error("Error sending email code:", error);
      throw error;
    }
  }

  public async getRequests(token: string): Promise<any> {
    try {
      return await chiefService.get(
        `${this.baseUrl}/advancement/request`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error("Error sending email code:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdvancementService();
