import "./SimulateAdvancement.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "../../ui/AppBar/AppBar";
import Button from "../../ui/Button/Button";
import TextBox from "../../ui/TextBox/TextBox";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { RootState } from "../../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import { setTransaction } from "../../Store/RequestAdvance";
import { ITransaction } from "../../Models/Advancement";

interface ISimulateAdvancement {
  rent: {
    value: number;
    formatted: string;
  };
  months: number;
}

function SimulateAdvancementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeTransaction = useSelector(
    (state: RootState) => state.requestAdvance.transaction
  );

  const formatNumber = (value: number): string => {
    return new Intl.NumberFormat("en-US").format(value);
  };

  const formSectionRef = useRef<HTMLDivElement | null>(null);
  const [simulateAdvancement, setAdvancement] = useState<ISimulateAdvancement>({
    rent: {
      value: storeTransaction.rentAmount || 0,
      formatted:
        storeTransaction.rentAmount > 0
          ? formatNumber(storeTransaction.rentAmount)
          : "",
    },
    months: storeTransaction.advanceMonths || 0,
  });
  const [showResults, setShowResults] = useState(false);

  const convertStringToNumber = (value: string): number => {
    const cleanedValue = value.replace(/,/g, "");
    return Number(cleanedValue);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    callback?: () => void
  ) => {
    const { name, value } = e.target;
    const advancementUpdate: ISimulateAdvancement = { ...simulateAdvancement };
    const keys = name.split(".");
    let currentLevel: any = advancementUpdate;
    keys.slice(0, -1).forEach((key) => {
      currentLevel = currentLevel[key as keyof typeof currentLevel];
    });
    currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] =
      convertStringToNumber(value);

    setAdvancement(advancementUpdate);

    if (callback) {
      callback();
    }
  };

  const handleSubmit = () => {
    if (simulateAdvancement.rent.value > 0 && simulateAdvancement.months > 0) {
      setShowResults(true);
    }
  };

  const handleNextStep = () => {
    dispatch(
      setTransaction({
        rentAmount: simulateAdvancement.rent.value,
        advanceMonths: simulateAdvancement.months,
        total: calculateTotal().total,
        commission: calculateTotal().commission,
      } as ITransaction)
    );
    navigate("/solicitar-adelanto");
  };

  const calculateTotal = () => {
    const total = simulateAdvancement.rent.value * simulateAdvancement.months;
    const baseCommissionRate = 0.165;
    const extraCommissionRate =
      simulateAdvancement.months > 6
        ? (simulateAdvancement.months - 6) * 0.012
        : 0;

    const commissionRate = baseCommissionRate + extraCommissionRate;
    const commission = parseFloat((total * commissionRate).toFixed(2));

    return {
      total: total - commission,
      totalFormatted: formatNumber(total - commission),
      commission: commissionRate,
    };
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <AppBar allowedSignIn={true} />

      <div className="simulate-advancement">
        <div className="simulate-advancement__container">
          <div className="simulate-advancement__container--left-section">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/galaxa-ouner.firebasestorage.app/o/ouner_affiliates_hero.webp?alt=media&token=18566143-6490-4950-96c3-5e5c4fb27682"
              alt="Simulación de adelanto"
            />
          </div>

          <div className="simulate-advancement__container--right-section">
            <h1>Simula tu adelanto de renta</h1>

            <div className="form-container" ref={formSectionRef}>
              <div className="form-container--fields">
                <TextBox
                  name="rent.value"
                  placeholder="¿Cuánto cobras de renta al mes?"
                  value={simulateAdvancement.rent.formatted}
                  type="number"
                  onChange={(e) => {
                    const formatValue = () => {
                      const advancementUpdate: ISimulateAdvancement = {
                        ...simulateAdvancement,
                      };
                      const value = simulateAdvancement.rent.value;

                      advancementUpdate.rent.formatted =
                        value === 0
                          ? ""
                          : formatNumber(simulateAdvancement.rent.value);
                    };

                    handleChange(e, formatValue);
                  }}
                />

                <TextBox
                  name="months"
                  placeholder="¿Cuántos meses quieres adelantar?"
                  value={simulateAdvancement.months}
                  type="number"
                  onChange={handleChange}
                />
              </div>
              <span className="helper-text">Hasta 36 meses</span>

              {(!showResults ||
                simulateAdvancement.rent.value === 0 ||
                simulateAdvancement.months === 0) && (
                <div className="button-container">
                  <Button
                    variant="filled-inverse"
                    text="Simular adelanto de renta"
                    icon={<ArrowForwardIosIcon />}
                    onClick={handleSubmit}
                    disabled={
                      simulateAdvancement.rent.value <= 0 ||
                      simulateAdvancement.months < 1 ||
                      simulateAdvancement.months > 35
                    }
                  />
                </div>
              )}
              {simulateAdvancement.rent.value > 0 &&
                simulateAdvancement.months > 0 &&
                showResults && (
                  <div>
                    <div className="result-text">
                      Te adelantamos hasta{" "}
                      <strong>
                        $
                        {simulateAdvancement.rent.value &&
                        simulateAdvancement.months
                          ? calculateTotal().totalFormatted
                          : "0"}{" "}
                        MXN
                      </strong>{" "}
                      por <strong>{simulateAdvancement.months || "0"}</strong>{" "}
                      meses de renta. Sin esperas, ni complicaciones
                      <small>
                        *Cálculo estimado y queda sujeto a aprobación y otros
                        factores
                      </small>
                    </div>
                    <div className="button-container">
                      <Button
                        variant="filled-inverse"
                        text="Solicitar adelanto de renta"
                        icon={<ArrowForwardIosIcon />}
                        onClick={handleNextStep}
                        disabled={
                          simulateAdvancement.rent.value <= 0 ||
                          simulateAdvancement.months < 1 ||
                          simulateAdvancement.months > 35
                        }
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SimulateAdvancementPage;
