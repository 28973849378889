import "./RequestAdvance.scss";

import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";

import AppBar from "../../ui/AppBar/AppBar";

import RequestAdvancementStepOne from "../../Components/RequestAdvance/StepOne/StepOne";
import RequestAdvancementStepTwo from "../../Components/RequestAdvance/StepTwo/StepTwo";
import RequestAdvancementStepThree from "../../Components/RequestAdvance/StepThree/StepThree";
import advancementService from "../../Services/AdvancementService";
import { useAppSelector } from "../../Store/Store";

function RequestAdvance() {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const accessToken = useAppSelector((state) => state.user.token) ?? "";
  const { transaction, actors, contractDetails } = useAppSelector(
    (state) => state.requestAdvance
  );

  const steps = [
    {
      title: "Datos de identificación",
      component: RequestAdvancementStepOne,
    },
    {
      title: "Datos del inmueble",
      component: RequestAdvancementStepTwo,
    },
    {
      title: "Revisar",
      component: RequestAdvancementStepThree,
    },
  ];

  const Stepper = () => {
    return (
      <div className="stepper">
        {steps.map((step, index) => {
          let status =
            index < currentStep
              ? "completed"
              : index === currentStep
              ? "in-progress"
              : "to-be-done";

          return (
            <div
              key={index}
              className={`stepper__step stepper__step--${status}`}
            >
              {status === "completed" ? <CheckIcon /> : `${index + 1}`}
              {status === "completed" ? (
                ""
              ) : (
                <span className="separator"> - </span>
              )}
              <span className="title"> {step.title}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const handleNextStep = () => {
    if (currentStep !== steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      submitData();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const submitData = async () => {
    await advancementService.request(
      {
        transaction,
        actors,
        contractDetails,
      },
      accessToken
    );

    navigate("/adelantos");
  };

  const currentComponentProps = {
    nextStep: () => handleNextStep(),
    previousStep: () => handlePreviousStep(),
  };

  return (
    <div className="request-advance">
      <AppBar allowedSignIn={true} />

      <div className="request-advance__container">
        <Stepper />

        {React.createElement(
          steps[currentStep].component,
          currentComponentProps
        )}
      </div>
    </div>
  );
}

export default RequestAdvance;
