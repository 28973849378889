import chiefService from './ChiefService';

export type TIntent = "sign-in" | "sign-up";

class AuthService {
  private baseUrl: string = process.env.REACT_APP_CHIEF_SERVICE || "";

  public async sendEmailCode(intent: TIntent, email: string, name: string): Promise<any> {
    try {
      return await chiefService.post(`${this.baseUrl}/auth/send-email-code`, {
        source: intent,
        email: email,
        name: name,
      }, {});
    } catch (error) {
      console.error("Error sending email code:", error);
      throw error;
    }
  }

  public async login(name: string, intent: TIntent, email: string, code: string): Promise<any> {
    try {
      return await chiefService.post(`${this.baseUrl}/auth/login`, {
        email: email,
        code: code,
        source: intent,
        name: name,
      }, {});
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
