import "./AppBar.scss";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import IconButton from "../../ui/IconButton/IconButton";
import Button from "../../ui/Button/Button";

import logo from "../../Assets/brand.png";
import { logOut } from "../../Store/User";

interface AppBarProps {
  allowedSignIn?: boolean;
  allowedSignOut?: boolean;
  children?: React.ReactNode;
}

const AppBar: React.FC<AppBarProps> = ({
  allowedSignIn,
  allowedSignOut,
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const SignInOption = () => (
    <Button
      icon={<LoginIcon />}
      variant="text-icon"
      text="Acceder"
      onClick={() => navigate("/login")}
    />
  );

  const SignOutOption = () => (
    <Button
      icon={<LogoutIcon />}
      variant="text-icon"
      text="Cerrar sesión"
      onClick={() => {
        dispatch(logOut());
        navigate("/login");
      }}
    />
  );

  return (
    <>
      <header className="app-bar">
        <div className="app-bar__actions">
          <a href="https://ouner.mx" className="brand">
            <img src={logo} alt="Ouner Logo" />
          </a>

          <div className="options">
            {children && children}
            {allowedSignIn && <SignInOption />}
            {allowedSignOut && <SignOutOption />}
          </div>

          <span className="menu--button" onClick={toggleMenu}>
            <IconButton variant="elevated-icon">
              {menuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </span>
        </div>

        {menuOpen && (
          <div className="app-bar__menu">
            {children && children}
            {allowedSignIn && <SignInOption />}
            {allowedSignOut && <SignOutOption />}
          </div>
        )}
      </header>
    </>
  );
};

export default AppBar;
