import { createSlice } from "@reduxjs/toolkit";

import { OUNER_SESSION } from "../consts";
import { RootState } from "./Store";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: localStorage.getItem(OUNER_SESSION),
  },
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem(OUNER_SESSION, action.payload);

      state.token = action.payload;
    },
    logOut: (state) => {
      localStorage.removeItem(OUNER_SESSION);

      state.token = null;
    },
  },
});

export const isUserLoggedIn = (state: RootState) => Boolean(state.user.token);

export const { setToken, logOut } = userSlice.actions;

export default userSlice;
