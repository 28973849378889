import "./Button.scss";

import React from "react";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant:
    | "filled"
    | "filled-inverse"
    | "outlined"
    | "text"
    | "text-icon"
    | "elevated"
    | "tonal"
    | "elevated-icon";
  text: string;
  icon?: React.ReactNode;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  onClick,
  icon,
  disabled,
  className,
  ...props
}) => {
  return (
    <button
      className={`button ${icon ? "button--icon" : ""} button--${variant} ${
        className ?? ""
      }`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {icon && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
    </button>
  );
};

export default Button;
